<template>
  <div class="result">
    <div class="loading" v-if="loading">
      <div class="mask">
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/suc-open-loading.gif"
        />
        <div>正在开门，请稍侯…</div>
      </div>
    </div>
    <template v-else>
      <div v-if="status" class="content">
        <img class="status-icon" src="../assets/imgs/result-success.png" />
        <div v-if="type === 3" class="status-msg">
          <span>支付成功</span>
          <span>请从下方取货口取走您的商品</span>
        </div>
        <div v-else class="status-msg">
          <span>柜门已打开</span>
          <span>请从下图所示位置取走商品</span>
        </div>
        <div v-if="type === 1" class="status-car twoDoors">
          <!-- 托盘顺序有改动，A C / B D  -->
          <div class="tray tray-A">
            <img v-if="regions.includes('A')" src="../assets/imgs/trayA.png" />
          </div>
          <div class="tray tray-B">
            <img v-if="regions.includes('C')" src="../assets/imgs/trayC.png" />
          </div>
          <div class="tray tray-C">
            <img v-if="regions.includes('B')" src="../assets/imgs/trayB.png" />
          </div>
          <div class="tray tray-D">
            <img v-if="regions.includes('D')" src="../assets/imgs/trayD.png" />
          </div>
        </div>
        <div v-else-if="type === 2" class="status-car threeDoors">
          <div class="tray tray-A">
            <img v-if="regions.includes('A')" src="../assets/imgs/trayA.png" />
          </div>
          <div class="tray tray-B">
            <img v-if="regions.includes('B')" src="../assets/imgs/trayB.png" />
          </div>
          <div class="tray tray-C">
            <img v-if="regions.includes('C')" src="../assets/imgs/trayC.png" />
          </div>
          <div class="tray tray-D">
            <img v-if="regions.includes('D')" src="../assets/imgs/trayD.png" />
          </div>
          <div class="tray tray-E">
            <img v-if="regions.includes('E')" src="../assets/imgs/trayE.png" />
          </div>
          <div class="tray tray-F">
            <img v-if="regions.includes('F')" src="../assets/imgs/trayF.png" />
          </div>
        </div>
      </div>
      <div v-else class="content failure">
        <div class="status-car"></div>
        <div class="status-msg">
          <span>抱歉，柜门未正常打开</span>
        </div>
      </div>
      <div v-if="type === 3" class="btns bounce">
        <div @click="toHome" class="homeBtn">返回首页</div>
      </div>
      <div v-else class="btns">
        <div @click="toHome" class="homeBtn">返回首页</div>
        <div class="reopen" @click="reopen" :class="{ disabled }">
          <span>重开柜门</span>
          <span v-if="!disabled">({{ remaining }}s)</span>
        </div>
      </div>
      <div class="footer">
        <a href="tel:400-666-7876">
          <b class="img" />
          <span>联系客服</span>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import {
  smartCabinetReopen,
  getOpenDoorStatusByOrderCode,
  orderDetail,
} from '@/api/api';
export default {
  timer: null,
  mounted() {
    const orderCode = this.$router.history.current.query.orderCode ?? '';
    this.orderCode = orderCode;
    this.checkDoorStatus();
  },
  computed: {
    disabled() {
      return this.remaining <= 0;
    },
  },
  data() {
    return {
      type: 1, // 1.两开门，2.三开门, 3.弹簧柜
      status: true,
      remaining: 0,
      loading: false,
      regions: [],
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    toHome() {
      window.location.href = 'neolixjs://webview?url=home';
    },
    reopen() {
      if (this.disabled) return;
      if (this.orderCode) {
        this.loading = true;
        smartCabinetReopen({ orderCode: this.orderCode })
          .then(() => {
            this.status = true;
          })
          .catch(() => {
            this.status = false;
          })
          .finally(() => {
            this.loading = false;
            clearInterval(this.timer);
            this.remaining = 0;
          });
      }
    },
    checkDoorStatus() {
      if (this.orderCode) {
        this.loading = true;
        getOpenDoorStatusByOrderCode({ orderCode: this.orderCode })
          .then((res) => {
            const status = res?.openDoorStatus;
            if (status) {
              this.status = status;
              this.getOrderDetail();
            } else {
              this.status = false;
              this.regions = [];
            }
          })
          .catch(() => {
            this.status = false;
            this.regions = [];
          })
          .finally(() => {
            this.loading = false;
            this.countDown();
          });
      }
    },
    countDown() {
      clearInterval(this.timer);
      this.remaining = 18;
      this.timer = setInterval(() => {
        this.remaining--;
        if (this.remaining <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    getOrderDetail() {
      orderDetail({ orderCode: this.orderCode })
        .then((res) => {
          this.type =
            res?.cabinCode === 'R' || res?.cabinCode === 'E'
              ? 2
              : res?.cabinCode === 'B'
              ? 3
              : 1;
          this.regions = res?.skuList.map((d) => d.region) ?? [];
        })
        .catch(() => {
          this.regions = [];
        });
    },
  },
};
</script>

<style scoped lang="scss">
[data-theme='light'] {
  .result {
    background: white;
  }

  .footer {
    a {
      &:active {
        background: #d9d9d9;
      }
    }
  }

  .homeBtn {
    &:active {
      background: #c8c9cd;
    }
  }

  .reopen {
    &:not(.disabled) {
      &:active {
        background: #d95234;
        color: #d9d9d9;
      }
    }
  }
}

.result {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .content {
    &.failure {
      .status-car {
        background: url('../assets/imgs/result-failure.png') center no-repeat;
        background-size: cover;
      }
    }

    margin-top: 0.15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .status-icon {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.24rem;
    }

    .status-msg {
      span {
        display: block;
        text-align: center;

        &:nth-child(1) {
          font-weight: bold;
          font-size: 0.48rem;
          color: #222222;
          @include font_color('title_color');
          margin: 0.24rem 0 0.16rem;
        }

        &:nth-child(2) {
          font-size: 0.28rem;
          color: #222222;
          @include font_color('title_color');
        }
      }
    }

    .status-car {
      margin: 0.6rem 0 0.4rem;
      width: 5.48rem;
      height: 3.49rem;
      position: relative;

      .tray {
        position: absolute;

        img {
          width: inherit;
          height: inherit;
          display: block;
        }
      }

      &.twoDoors {
        background: url('../assets/imgs/result-car.png') center no-repeat;
        background-size: cover;

        .tray {
          width: 1.28rem;
          height: 0.4rem;

          &-A {
            left: 1.28rem;
            top: 1.04rem;
          }

          &-B {
            right: 1.02rem;
            top: 1.04rem;
          }

          &-C {
            left: 1.28rem;
            bottom: 1.61rem;
          }

          &-D {
            right: 1.02rem;
            bottom: 1.61rem;
          }
        }
      }

      &.threeDoors {
        background: url('../assets/imgs/result-car2.png') center no-repeat;
        background-size: cover;

        .tray {
          width: 0.96rem;
          height: 0.39rem;

          &-A {
            left: 1.28rem;
            top: 1.04rem;
          }

          &-B {
            left: 1.28rem;
            bottom: 1.61rem;
          }

          &-C {
            left: 2.39rem;
            top: 1.04rem;
          }

          &-D {
            left: 2.39rem;
            bottom: 1.61rem;
          }

          &-E {
            right: 1.02rem;
            top: 1.04rem;
          }

          &-F {
            right: 1.02rem;
            bottom: 1.61rem;
          }
        }
      }
    }
  }

  .btns {
    margin-top: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.48rem;

    &.bounce {
      justify-content: center;

      div {
        width: 3.55rem;
      }
    }

    div {
      width: 3.12rem;
      height: 0.92rem;
      text-align: center;
      font-size: 0.3rem;
      border-radius: 0.48rem;
      line-height: 0.92rem;
      font-weight: bold;
      color: black;
      @include font_color('result_btn_color');
      background: #ecedf2;
      @include background_color('result_btn_bg');

      &.reopen {
        color: white;
        background: #ff613e;

        &.disabled {
          background: #f1f2f8;
          @include background_color('result_disabled_btn_bg');
          color: white;
          @include font_color('result_disabled_btn_color');
        }
      }
    }
  }

  .footer {
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    -webkit-tap-highlight-color: transparent;

    a {
      margin: 0 auto 0.4rem;
      display: flex;
      width: 2.14rem;
      height: 0.64rem;
      background: #ffffff;
      @include background_color('result_bg');
      border: 0.02rem solid #e5e5e5;
      @include border_color('result_consulting_border');
      border-radius: 0.32rem;
      align-items: center;
      justify-content: center;
      font-size: 0.28rem;
      color: #222222;
      @include font_color('title_color');
      text-decoration: none;

      .img {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.16rem;
        background-image: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/customer.png);
        @include bg_image('result_consulting_image');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .loading {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .mask {
      background: #111;
      border-radius: 0.2rem;
      padding: 0.3rem 0.4rem;
      font-size: 0.28rem;
      color: #fff;
      text-align: center;

      img {
        width: 1.2rem;
        height: 1.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }
}
</style>
